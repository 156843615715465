<template>
    <el-row class="login-container">
        <h1 class="login-logo"></h1>
        <el-form ref="AccountFrom" :model="account" :rules="rules" label-position="left" class="login-form">
            <el-form-item prop="username">
                <el-input type="text" v-model="account.username" auto-complete="off" placeholder="账号" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item prop="pwd">
                <el-input type="password" v-model="account.pwd" auto-complete="off" placeholder="密码" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item prop="captcha">
                <el-input type="text" prop="imgCode" v-model="account.captcha" placeholder="请输入图形验证码" @keyup.enter.native="handleLogin">
                    <template slot="append"><img class="img_code" src="" id="myCodeImg" @click="showCodeImg" /></template>
                </el-input>
            </el-form-item>

            <el-form-item>
                <el-col :span="12">
                    <el-checkbox type="checkbox" v-model="checked" value="" class="cbox">记住用户名</el-checkbox>
                </el-col>
                <el-col :span="4" :offset="8">
                    <el-popover ref="popover1" placement="right-start" title="密码丢失？" width="160" trigger="hover" content="请联系系统管理员找回密码。">
                    </el-popover>
                    <el-button type="text" v-popover:popover1>找回密码？</el-button>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width:100%" @click.native.prevent="handleLogin" :loading="logining">登 录</el-button>
            </el-form-item>
        </el-form>
        <el-col :span="24">
            <div class="footer">&copy; 版权所有 西安点趣信息技术有限公司</div>
        </el-col>
    </el-row>


</template>

<script>
    import {requestSystemLogin,reqCodeImg,downloadFileURL} from '../api/system-api';

    export default {
        data() {
            return {
                downloadFileURL:downloadFileURL,
                logining: false,
                account: {
                    username: '',
                    pwd: '',
                    captcha: ''
                },
                captchaImage:'',
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                    ],
                    pwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                    captcha: [
                        {required: true, message: '请输入图形验证码', trigger: 'blur'},
                    ]
                },
                checked: true
            };
        },
        methods: {
            setCookie(username, exdays) {
                var exdate = new Date()
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
                // 字符串拼接cookie
                window.document.cookie = 'systemUserName' + '=' + username + ';path=/;expires=' + exdate.toGMTString()
            },

// 读取cookie
            getCookie() {
                var that = this
                if (document.cookie.length > 0) {
                    var arr = document.cookie.split('; ')
                    for (var i = 0; i < arr.length; i++) {
                        var arr2 = arr[i].split('=')
                        // 判断查找相对应的值
                        if (arr2[0] === 'systemUserName') {
                            that.account.username = arr2[1]// 保存到保存数据的地方
                        }
                    }
                }
            },

// 清除cookie
            clearCookie() {
                this.setCookie('', '', -1)
            },

            handleLogin() {
                this.$refs.AccountFrom.validate((valid) => {
                    if (valid) {
                        this.logining = true;
                        var password = this.encode64(this.encode64(this.account.pwd) + 'pd');
                        var username = this.encode64(this.encode64(this.account.username) + 'un');
                        var loginParams = {username: username, password: password,captcha:this.account.captcha,captchaImage:this.captchaImage};
                        requestSystemLogin(loginParams).then(data => {
                            this.logining = false;
                            if (data.code === 1) {
                                this.$message({
                                    message: data.msg,
                                    type: 'success'
                                });
                                sessionStorage.setItem('Authorization', data.data.token);
                                if (this.checked == true) {
                                    console.info(this.checked);
                                    this.setCookie(this.account.username, 7);
                                } else {
                                    console.info(this.checked);
                                    this.clearCookie();
                                }
                                this.$router.push({path: '/dashboard'});
                            } else {
                                this.$message({
                                    message: data.msg,
                                    type: 'error'
                                });
                            }
                        }).catch(() => {
                            this.logining = false;
                            this.$message({
                                message: "登录异常",
                                type: 'error'
                            });
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },


            // base64加密开始
            encode64(input) {
                var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                    + "wxyz0123456789+/" + "=";
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;
                do {
                    chr1 = input.charCodeAt(i++);
                    chr2 = input.charCodeAt(i++);
                    chr3 = input.charCodeAt(i++);
                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;
                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }
                    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                        + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";
                } while (i < input.length);

                return output;
            },
            showCodeImg () {
                reqCodeImg().then((res) => {
                    if(res.data.code===1){
                        document.getElementById('myCodeImg').src = downloadFileURL + res.data.data.fileName + '.jpg/';
                        this.captchaImage=res.data.data.onlyImgCode;
                    }
                })
            },
        }, mounted() {
            this.getCookie();
            this.showCodeImg();
        }
    }

</script>
<style slot-scope="scoped">
    html, body {
        height: 100%;
        border: none;
    }

    body {
        background-color: #1f5d53;
        /* background-image: url(../assets/images/xshf-login-bg.png); */
        background-position: bottom center;
        background-repeat: no-repeat;
    }

    #app {
        background-image: url(../assets/images/xshf-login-bg.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        margin-top: 0;
    }

    .login-container {
        display: block;
        width: 420px;
        margin: 0 auto;
        color: #fff;
    }

    .login-logo {
        display: block;
        margin: 100px 0 35px;
        padding: 0;
        height: 202px;
        background-image: url(../assets/images/xshf-login-logo.png);
        background-position: bottom center;
        background-repeat: no-repeat;
    }

    .login-form {
        box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        background-clip: padding-box;
        width: 350px;
        padding: 35px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #285856;
    }

    .login-form .el-input-group__append { padding: 0; background-color: #ffeeb8; }

    .login-form .el-input-group__append .img_code { height: 35px; }

    .footer {
        margin: 20px 0;
        font-size: 13px;
        text-align: center;
        color: #c6dad9;
    }
</style>
